<template>
  <div class="center_box">
    <div class="top">
      <img src="@/assets/img/verification/验证成功.png" alt="" />
      <span>验证成功</span>
    </div>
    <div class="bottom">
      <table border="0" cellspacing="0">
        <tr>
          <td>存证时间：{{ successData.created | dateForMat }}</td>
          <td class="borderLeftN">存证编号：{{ successData.noid }}</td>
        </tr>
        <tr>
          <td class="bdC" colspan="2">
            区块高度：{{ successData.blockHeight }}
          </td>
        </tr>
        <tr>
          <td colspan="2">存证Hash：{{ successData.filehash }}</td>
        </tr>
        <tr>
          <td class="lastTd" colspan="2">交易Hash：{{ successData.txhash }}</td>
        </tr>
      </table>

      <ul class="miniScreen">
        <li>
          <span class="spanLabel">存证时间：</span>
          <span class="spanContent">{{
            successData.created | dateForMat
          }}</span>
        </li>
        <li>
          <span class="spanLabel">存证编号：</span>
          <span class="spanContent">{{ successData.noid }}</span>
        </li>
        <li>
          <span class="spanLabel">区块高度：</span>
          <span class="spanContent">{{ successData.blockHeight }}</span>
        </li>
        <li>
          <span class="spanLabel">存证Hash：</span>
          <span class="spanContent">{{ successData.filehash }}</span>
        </li>
        <li>
          <span class="spanLabel">交易Hash：</span>
          <span class="spanContent">{{ successData.txhash }}</span>
        </li>
      </ul>
      <el-button type="primary" @click="handleClick" class="btnSub"
        >返回重新验证</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    successData: {
      type: Object,
    },
  },
  methods: {
    handleClick() {
      this.$emit("comChange", { comName: "form_ver" });
    },
  },
  filters: {
    // 时间格式转换
    dateForMat: function (time) {
      // 补零
      const addZero = function (sum) {
        return sum > 9 ? sum : "0" + sum;
      };
      let date = new Date(time * 1000);
      let YY = date.getFullYear();
      let M = date.getMonth() + 1;
      let DD = date.getDate();
      let HH = date.getHours();
      let MM = date.getMinutes();
      let SS = date.getSeconds();
      return `${YY}/${M}/${DD} ${addZero(HH)}:${addZero(MM)}:${addZero(SS)}`;
    },
  },
};
</script>

<style lang="less" scoped>
.center_box {
  display: block;
  margin-top: 50px;
  .miniScreen {
    display: none;
  }
  .btnSub {
    margin-left: 0;
  }
  .top {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bottom {
    display: flex;
    height: 500px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    table {
      height: 140px;
      border-color: #dcdfe6;
    }
    td {
      border: 1px solid #dcdfe6;
      padding-left: 2em;
    }
    .borderLeftN {
      border-left: 0;
    }
    .bdC {
      border-top: 0;
      border-bottom: 0;
    }
    .lastTd {
      border-top: 0;
    }
    .btnSub {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (min-width: 768px) {
  .center_box {
    .bottom {
      table {
        width: 90vw;
        max-width: 940px;
      }
    }
  }
}
@media screen and (min-width: 670px) and(max-width:800px) {
  .center_box {
    .bottom {
      table {
        width: 96%;
        // margin: 0 30px;
      }
    }
  }
}

@media screen and(max-width:670px) {
  .center_box {
    margin-top: 10px;
    .bottom {
      height: 100%;
      .btnSub {
        margin-top: 10px;
      }
      table {
        display: none;
      }
      .miniScreen {
        display: block;
        border: 1px solid #ccc;
        border-radius: 3px;
        margin: 20px;

        > li {
          padding: 0 20px;
          line-height: 30px;
          span {
            color: #666;
          }
          .spanContent {
            display: -webkit-box;
            display: inline-block;
            word-break: break-all;
            -webkit-box-orient: vertical;
            overflow: hidden;
            width: 15.625rem;
            color: #333;
          }
        }
      }
    }
  }
}
</style>